import React from 'react'

import background from '../assets/images/bg-header.png'
import flag from '../assets/images/no-flag.png'

const VehicleInfo = ({ vehicle }) => {
  return (
    <div
      className="vehicle-info"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div className="flag">
        <div>
          <img alt="flag" src={flag} />
        </div>
        <div>{vehicle?.kjennemerke}</div>
      </div>
      <div className="vh-type">{`${vehicle?.merkeNavn} ${vehicle?.modellbetegnelse} ${vehicle?.regAAr}`}</div>
    </div>
  )
}

export default VehicleInfo
