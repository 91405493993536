export const SET_STEP = 'SET_STEP'
export const GET_VEHICLES = 'GET_VEHICLES'
export const GET_SERVICES = 'GET_SERVICES'
export const ADD_SERVICE = 'ADD_SERVICE'
export const REMOVE_SERVICE = 'REMOVE_SERVICE'
export const SERVICE_AGREEMENT = 'SERVICE_AGREEMENT'
export const FIND_PERSON = 'FIND_PERSON'
export const SET_PERSON = 'SET_PERSON'
export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const VEHICLE_ERROR = 'VEHICLE_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const SET_LOADING = 'SET_LOADING'
