import React, { Fragment } from 'react'
import VehicleState, { useVehicles } from '../components/booking/context/vehicle/VehicleState'

import Vehicles from '../components/booking/vehicle/Vehicle'
import Services from '../components/booking/vehicle/Service'
import PersonlInfo from '../components/booking/vehicle/PersonalInfo'
import Summary from '../components/booking/vehicle/Summary'

import { device } from '../components/device'
import styled from 'styled-components'
import '../components/booking/layout/css/style.css'

export const SectionTitleELementes = styled.h1`
  margin-bottom: 4rem;
  text-align: center;
  font-weight: 700;
  color: #333333;
  @media only screen and ${device.tabletMaxL} {
    margin-bottom: 2rem;
    font-size: 3em;
    text-align: center;
  }
  @media (max-width: 1441px) {
    margin-bottom: 2rem;
    font-size: 3em;
    text-align: center;
  }
`

const Container = ({ workshop }) => {
  const [vehicleState] = useVehicles()
  const { step } = vehicleState

  if (step === 1) return <Vehicles workshop={workshop} />
  else if (step === 2) return <Services workshop={workshop} />
  else if (step === 3 || step === 4) return <PersonlInfo workshop={workshop} />
  else if (step === 5) return <Summary workshop={workshop} />
  else return <div>&nbsp;</div>
}

//Booking components comment just to trigger deployment
export const Booking = ({ workshop }) => {
  return (
    <VehicleState>
      <Fragment>
        <div className="container-main">
          <Fragment>
            {/* <Sidebar /> */}
            <SectionTitleELementes>Bestill time på 1-2-3</SectionTitleELementes>
            <div className="view">
              <div className="wraper">
                <Container workshop={workshop} />
              </div>
            </div>
          </Fragment>
        </div>
      </Fragment>
    </VehicleState>
  )
}

export default Booking
