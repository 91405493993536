import React, { useContext } from 'react'
import { useVehicles, getVehicles } from '../context/vehicle/VehicleState'
import { RegNoContext } from '../../../context/regno'
import { useDataLayer } from '../../../hooks/use-data-layer'

import wizard from '../assets/images/step1.png'
import Alert from '../layout/Alert'
import '../layout/css/vehicle.css'
import Spinner from '../layout/Spinner'

const Vehicles = ({ workshop }) => {
  const pushDataLayerEvent = useDataLayer()
  const { regNo, setRegNo } = useContext(RegNoContext)
  const [vehicleState, vehicleDispatch] = useVehicles()
  const { error, loading } = vehicleState

  const onChange = (e) => setRegNo(e.target.value.toUpperCase())

  return (
    <div className="w1">
      <div className="wizard">
        <img alt="wizard" src={wizard} />
      </div>
      {error && <Alert type="error" message={error} />}
      {loading && <Spinner />}
      <div className="container">
        <h3>Bestill time på 1-2-3</h3>
        <div>
          <label>Reg. nr.</label>
          <input type="text" maxLength={7} name="regNo" value={regNo} onChange={onChange} />
        </div>
        <div className="footer center">
          <span>
            Kontakt oss på tlf.{' '}
            <a className="link" href={`tel:${workshop?.phone}`}>
              {workshop?.phone}
            </a>{' '}
            eller{' '}
            <a className="link" href={`mailto:${workshop?.email}`}>
              {workshop?.email}
            </a>{' '}
            eller fyll inn bilens regnr for å starte booking.
          </span>
          <input
            type="button"
            value="NESTE"
            onClick={() => {
              pushDataLayerEvent({ event: 'bestillTime' })
              getVehicles(vehicleDispatch, regNo)
            }}
            disabled={regNo?.length === 0}
          />
        </div>
      </div>
    </div>
  )
}

export default Vehicles
