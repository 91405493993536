import React from 'react'
import { navigate } from 'gatsby'

import VehicleInfo from './VehicleInfo'
import { useVehicles, clearFilter, setStep } from '../context/vehicle/VehicleState'

import wizard from '../assets/images/step4.png'
import logo from '../assets/images/logo.png'
import '../layout/css/summary.css'

const Summary = ({ workshop }) => {
  const [vehicleState, vehicleDispatch] = useVehicles()
  const { vehicles, person, filtered } = vehicleState

  const ScrollToTopOnMount = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div className="w4">
      <div className="wizard">
        <img alt="wizard" src={wizard} />
      </div>
      <div className="container">
        <VehicleInfo vehicle={vehicles} />
        <div className="heading">
          <h3>Din forespørsel er sent!</h3>
          <span className="desc">Vi tar kontakt med deg så snart som mulig.</span>
        </div>
        <div className="summary">
          <h3>Bestilling Oversikt</h3>
          <div className="table">
            <div className="row">
              <div className="col-1">Sted</div>
              <div className="col-11">
                <span>{workshop.name}</span>
                <span>{workshop.street}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-1">Type</div>
              <div className="col-11 type">
                {filtered &&
                  filtered
                    .map((item, key) => {
                      return item.name
                    })
                    .join(', ')}
                {filtered.length === 0 && <span></span>}
              </div>
            </div>
            <div className="row">
              <div className="col-1">Beskjed</div>
              <div className="col-11">
                <>
                  <span>{person.request}</span>
                  <span>{person.message}</span>
                </>
              </div>
            </div>
            <div className="row">
              <div className="col-1">Bil</div>
              <div className="col-11">
                <span>{vehicles?.kjennemerke}</span>
                <span>{`${vehicles?.merkeNavn} ${vehicles?.modellbetegnelse} ${vehicles?.regAAr}`}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-1">&nbsp;</div>
              <div className="col-11">
                <img alt="logo" src={logo} />
              </div>
            </div>
          </div>
          <div className="actions">
            <input
              type="button"
              value="FERDIG"
              onClick={() => {
                const url = typeof window !== 'undefined' ? window.location : ''
                url.hash = ''
                navigate(`${url}order`)
                clearFilter(vehicleDispatch)
                setStep(vehicleDispatch, 1, '')
                ScrollToTopOnMount()
              }}
            ></input>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Summary
